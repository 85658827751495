// 此文件为生成文件，不要直接编辑
import _menu from 'C:/Users/Pengsy/Desktop/touch/src/models/menu.js';
import _page from 'C:/Users/Pengsy/Desktop/touch/src/models/page.js';
import _settings from 'C:/Users/Pengsy/Desktop/touch/src/models/settings.js';
import _side from 'C:/Users/Pengsy/Desktop/touch/src/models/side.js';
import _system from 'C:/Users/Pengsy/Desktop/touch/src/models/system.js';

export const menu = _menu;
export const page = _page;
export const settings = _settings;
export const side = _side;
export const system = _system;

