import React, {Component} from 'react';
import {Select} from 'antd';
import {sxAjax as ajax} from '@/commons/ajax';


export default class Index extends Component {

    static getDerivedStateFromProps(props) {
        if ('color' in props) {
            return {
                color: props.color,
            };
        }
        return null;
    }

    state = {
        options : []
    };

    componentDidMount = ()=> {
        const { typeKey } = this.props;
        ajax.get(`/admin/dict/type/${typeKey}`).then( res=> {
            let options = []
            for(let i= 0; i< res['data'].length; i++) {
                options.push({
                    label: res['data'][i].label,
                    value: res['data'][i].value
                })
            }
            this.setState({
                options
            })
        })

    }

    render() {
        const {...others} = this.props;
        return (
            <Select {...others}>
                {
                    this.state.options.map(opt => <Select.Option key={opt.value} {...opt}>{opt.label}</Select.Option>)
                }
            </Select>
        )
    }
}
