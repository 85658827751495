// 此文件是通过脚本生成的，直接编辑无效！！！

// 不需要导航框架的页面路径
export const noFrames = [
    '/login',
    '/touch/active',
    '/touch/active/join',
    '/touch/codelogin',
    '/touch/health',
    '/touch/home',
    '/touch/member',
    '/touch/mylog',
    '/',
    '/touch/recognition',
    '/touch/health/service',
];

// 不需要登录就可以访问的页面路径
export const noAuths = [
    '/login',
    '/touch/health',
    '/touch/mylog',
    '/touch/health/service',
];

// 需要keep alive 页面
export const keepAlives = [
    {
        path: '/iframe_page_/:src',
        keepAlive: true,
    },
    {
        path: '/login',
        keepAlive: false,
    },
    {
        path: '/touch/active',
        keepAlive: false,
    },
    {
        path: '/touch/active/join',
        keepAlive: false,
    },
    {
        path: '/touch/codelogin',
        keepAlive: false,
    },
    {
        path: '/touch/health',
        keepAlive: false,
    },
    {
        path: '/touch/home',
        keepAlive: false,
    },
    {
        path: '/touch/member',
        keepAlive: false,
    },
    {
        path: '/touch/mylog',
        keepAlive: false,
    },
    {
        path: '/',
        keepAlive: false,
    },
    {
        path: '/touch/recognition',
        keepAlive: false,
    },
    {
        path: '/touch/health/service',
        keepAlive: false,
    },
];

// 页面路由配置
export default [
    {
        path: '/admin/dept/index',
        component: () => import('C:/Users/Pengsy/Desktop/touch/src/pages/admin/dept/index.jsx'),
    },
    {
        path: '/admin/dict/index',
        component: () => import('C:/Users/Pengsy/Desktop/touch/src/pages/admin/dict/index.jsx'),
    },
    {
        path: '/admin/file/index',
        component: () => import('C:/Users/Pengsy/Desktop/touch/src/pages/admin/file/index.jsx'),
    },
    {
        path: '/admin/log/index',
        component: () => import('C:/Users/Pengsy/Desktop/touch/src/pages/admin/log/index.jsx'),
    },
    {
        path: '/admin/menu/index',
        component: () => import('C:/Users/Pengsy/Desktop/touch/src/pages/admin/menu/index.jsx'),
    },
    {
        path: '/admin/role/index',
        component: () => import('C:/Users/Pengsy/Desktop/touch/src/pages/admin/role/index.jsx'),
    },
    {
        path: '/admin/route/index',
        component: () => import('C:/Users/Pengsy/Desktop/touch/src/pages/admin/route/index.jsx'),
    },
    {
        path: '/users/_/edit/:id',
        component: () => import('C:/Users/Pengsy/Desktop/touch/src/pages/admin/tenant/Edit.jsx'),
    },
    {
        path: '/admin/tenant/index',
        component: () => import('C:/Users/Pengsy/Desktop/touch/src/pages/admin/tenant/index.jsx'),
    },
    {
        path: '/users/_/edit/:id',
        component: () => import('C:/Users/Pengsy/Desktop/touch/src/pages/admin/users/Edit.jsx'),
    },
    {
        path: '/admin/user/index',
        component: () => import('C:/Users/Pengsy/Desktop/touch/src/pages/admin/users/index.jsx'),
    },
    {
        path: '/example/ajax',
        component: () => import('C:/Users/Pengsy/Desktop/touch/src/pages/examples/ajax/index.jsx'),
    },
    {
        path: '/example/customer-header',
        component: () => import('C:/Users/Pengsy/Desktop/touch/src/pages/examples/customer-header/index.jsx'),
    },
    {
        path: '/gen/datasource',
        component: () => import('C:/Users/Pengsy/Desktop/touch/src/pages/gen/datasource/index.jsx'),
    },
    {
        path: '/gen/index',
        component: () => import('C:/Users/Pengsy/Desktop/touch/src/pages/gen/gen/index.jsx'),
    },
    {
        path: '/iframe_page_/:src',
        component: () => import('C:/Users/Pengsy/Desktop/touch/src/pages/iframe/index.jsx'),
    },
    {
        path: '/login',
        component: () => import('C:/Users/Pengsy/Desktop/touch/src/pages/login/index.jsx'),
    },
    {
        path: '/menu-permission',
        component: () => import('C:/Users/Pengsy/Desktop/touch/src/pages/menu-permission/index.jsx'),
    },
    {
        path: '/settings',
        component: () => import('C:/Users/Pengsy/Desktop/touch/src/pages/setting/index.jsx'),
    },
    {
        path: '/touch/active',
        component: () => import('C:/Users/Pengsy/Desktop/touch/src/pages/touch/active/index.jsx'),
    },
    {
        path: '/touch/active/join',
        component: () => import('C:/Users/Pengsy/Desktop/touch/src/pages/touch/active/join/index.jsx'),
    },
    {
        path: '/touch/codelogin',
        component: () => import('C:/Users/Pengsy/Desktop/touch/src/pages/touch/codelogin/index.jsx'),
    },
    {
        path: '/touch/health',
        component: () => import('C:/Users/Pengsy/Desktop/touch/src/pages/touch/health/index.jsx'),
    },
    {
        path: '/touch/home',
        component: () => import('C:/Users/Pengsy/Desktop/touch/src/pages/touch/home/index.jsx'),
    },
    {
        path: '/touch/member',
        component: () => import('C:/Users/Pengsy/Desktop/touch/src/pages/touch/member/index.jsx'),
    },
    {
        path: '/touch/mylog',
        component: () => import('C:/Users/Pengsy/Desktop/touch/src/pages/touch/mylog/index.jsx'),
    },
    {
        path: '/',
        component: () => import('C:/Users/Pengsy/Desktop/touch/src/pages/touch/recognition/index.jsx'),
    },
    {
        path: '/touch/recognition',
        component: () => import('C:/Users/Pengsy/Desktop/touch/src/pages/touch/recognition2/index.jsx'),
    },
    {
        path: '/touch/health/service',
        component: () => import('C:/Users/Pengsy/Desktop/touch/src/pages/touch/service/index.jsx'),
    },
];
